<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Web Sayfaları </template>
      <template #content>
        <div class="top-button-area">
          <Button
            icon="pi pi-plus "
            class="p-button-sm p-mr-2 p-mb-2 yeni"
            label="Yeni"
            @click="_WebSayfaModal()"
          />
        </div>
        <DataTable
          v-if="DataTable_render"
          :columns="columns"
          :data="WebSayfaListesi"
          v-on:set-child-data="getDataChild"
        />
      </template>
    </Card>

    <Dialog
      v-model:visible="WebSayfaModal"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <template #header>
        <h4>Yeni Web Sayfa</h4>
      </template>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanSayfaAd">Sayfa Adı</span>
          <InputText
            type="text"
            v-model="v$.SayfaAd.$model"
            aria-labelledby="spanSayfaAd"
            :class="{ 'p-invalid': v$.SayfaAd.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.SayfaAd.$invalid && submitted) ||
              v$.SayfaAd.$pending.$response
            "
            class="p-error"
            >{{ v$.SayfaAd.required.$message.replace("Value", "Name") }}</small
          >
        </div>
        <div class="p-field p-col-6">
          <span for="spanSayfaUrl">Sayfa Url</span>
          <InputText
            type="text"
            v-model="v$.SayfaUrl.$model"
            aria-labelledby="spanRolTanim"
            :class="{ 'p-invalid': v$.SayfaUrl.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.SayfaUrl.$invalid && submitted) ||
              v$.SayfaUrl.$pending.$response
            "
            class="p-error"
            >{{ v$.SayfaUrl.required.$message.replace("Value", "Name") }}</small
          >
        </div>
      </div>

      <template #footer>
        <Button
          label="Tamam"
          icon="pi pi-check"
          class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)"
          autofocus
        />
        <Button
          label="Kapat"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="this._WebSayfaModal()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../../DataTable/DataTable.vue";
import GlobalServis from "../../../../services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      WebSayfaListesi: [],
      columns: [
        { field: "Ad",  header: "Sayfa Adı" },
        { field: "Url", header: "Sayfa Url" },
      ],
      WebSayfaModal: false,
      WebSayfaDurum: null,

      SayfaAd: null,
      SayfaUrl: null,
      WebSayfa_rowid: null,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.WebSayfaModal = !this.WebSayfaModal;
      this.WebSayfaDurum = false;
      this.WebSayfa_rowid = getdata_child.data.WebSayfa_rowid;
      let data = {
        WebSayfa_rowid: getdata_child.data.WebSayfa_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetWebSayfaInfo", data).then((res) => {
        if (res.status == 200) {
          this.v$.SayfaAd.$model = res.data[0].Ad;
          this.v$.SayfaUrl.$model = res.data[0].Url;
          this.WebSayfa_rowid = res.data[0].WebSayfa_rowid;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _WebSayfaModal() {
      this.WebSayfaDurum = true;
      this.WebSayfaModal = !this.WebSayfaModal;
      this.ResetForm();
      if (!this.WebSayfaModal) {
        // this.resetForm();
      }
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          WebSayfa_rowid: this.WebSayfa_rowid,
          Ad: this.SayfaAd,
          Url: this.SayfaUrl
        };
        if (this.WebSayfaDurum) {
          // Yeni WebSayfa
          GlobalServis.GlobalServis("POST", "SetWebSayfa", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Web Sayfa oluşturulmuştur",
                life: 3000,
              });
              this.Refresh();
              this._WebSayfaModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        } else {
          //Edit WebSayfa
          GlobalServis.GlobalServis("POST", "UpdateWebSayfa", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Rol düzenlemiştir",
                life: 3000,
              });
              this.Refresh();
              this._WebSayfaModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    ResetForm(){
      this.SayfaAd = null;
      this.SayfaUrl = null;
      this.WebSayfa_rowid = null;
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetWebSayfa", "").then((res) => {
        if (res.status == 200) {
          this.WebSayfaListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      SayfaAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SayfaUrl: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>